import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Tooltip } from '@mui/material';
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevronLeft.svg';
import useAppNaviagte from 'common/hooks/useAppNaviagate';
import ImageInputFile from 'common/ui/form/dndFileInput/ImageInputFile';
import SimpleMultiSelect from 'common/ui/form/dropdown/SimpleMultiSelect';
import { OptionData } from 'common/ui/form/types';
import { convertToHTML } from 'draft-convert';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';

import {
  CheckIcon,
  CloseIcon,
  EyeOffIcon,
  EyeOnIcon,
  FoldIcon,
  UnfoldIcon,
} from '../../../assets/icons';
import useToggle from '../../../common/hooks/useToggle';
import { SimpleMultiUserSelect } from '../../../common/ui/form/dropdown/SimpleMultiUserSelect';
import {SimpleSingleSelect, SingleSelect} from '../../../common/ui/form/dropdown/SingleSelect';
import RadioButton from '../../../common/ui/form/RadioButton';
import { ProgramDetailsFormData, ProgramDetailsSchema } from '../types';

import { UserData } from './useUsersDropDownOptions';
interface ProgramDetailsFormProps {
  defaultValues: ProgramDetailsFormData;
  equipmentRequiredOptions: OptionData<string>[];
  addonPurchaseIdOptions: OptionData<string>[];
  displayTagsOptions: OptionData<string>[];
  userOptions: OptionData<UserData>[];
  nextPrograms: OptionData<string>[];
  onSaveDraft: (formData: ProgramDetailsFormData) => void;
  goNextSection: (formData: ProgramDetailsFormData) => void;
  addItemToDisplayOptions: (item: string) => void;
  filterUsersOptions: (searchString: string) => void;
}

function ProgramDetailsForm(props: ProgramDetailsFormProps) {
  const {
    defaultValues,
    equipmentRequiredOptions,
    addonPurchaseIdOptions,
    nextPrograms,
    displayTagsOptions,
    userOptions,
    onSaveDraft,
    goNextSection,
    addItemToDisplayOptions,
    filterUsersOptions,
  } = props;
  const {
    control,
    clearErrors,
    getValues,
    setValue,
    setError,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<ProgramDetailsFormData>({
    defaultValues,
    resolver: zodResolver(ProgramDetailsSchema),
  });

  const { isOpen: isHidden, toggle } = useToggle(defaultValues.title_hidden);

  const { goBack } = useAppNaviagte();
  const onClickSaveAsDraft = async () => {
    clearErrors();
    const detailsPayload = { ...getValues() };
    if (detailsPayload.name.trim().length === 0) {
      setError('name', { type: 'custom', message: 'Atleast name is required to save draft.' });
    } else {
      onSaveDraft(detailsPayload);
    }
  };
  useEffect(() => {
    setValue('title_hidden', isHidden);
  }, [isHidden, setValue]);

  const [editorState, setEditorState] = useState(() => {
    const formData = getValues().description;
    if (formData) {
      let { contentBlocks, entityMap } = convertFromHTML(formData);
      const newContentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      return EditorState.createWithContent(newContentState);
    } else {
      return EditorState.createEmpty();
    }
  });

  useEffect(() => {
    let raw = convertToHTML(editorState.getCurrentContent());
    setValue('description', raw);
  }, [editorState, setValue]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(goNextSection)} className="h-pageContent pb-24">
      <div className="flex bg-gray-light h-full px-10 py-8 overflow-auto">
        <div className="w-1/3">
          <div className="w-70 mx-auto">
            <label className="mb-2">Cover Image</label>
            <Controller
              control={control}
              name="image"
              render={({ field }) => (
                <ImageInputFile
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  className="h-60 w-60 text-sm"
                />
              )}
            />
            <p className="fieldError">{errors?.image?.message}</p>
            <label className="mb-2">Overlay Image</label>
            <Controller
              control={control}
              name="image_overlay"
              render={({ field }) => (
                <ImageInputFile
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  className="h-60 w-60 text-sm"
                />
              )}
            />
            <p className="fieldError">{errors?.image_overlay?.message}</p>
          </div>
        </div>
        <div className="w-2/3">
          <div className="mb-6">
            <label className="mb-2">Workout Length</label>
            <div className="flex">
              <div className="w-1/2 pr-1">
                <RadioButton
                  className="h-10 w-full"
                  label="Quick (15 mins)"
                  value="quick"
                  {...register('type')}
                />
              </div>
              <div className="w-1/2 pl-1">
                <RadioButton
                  className="h-10 w-full"
                  label="Normal"
                  value="normal"
                  {...register('type')}
                />
              </div>
            </div>
            <p className="fieldError">{errors?.type?.message}</p>
          </div>
          <label className="my-2">Lock to User(s)</label>
          <Controller
            key="user_access"
            name="user_access"
            control={control}
            render={({ field }) => (
              <SimpleMultiUserSelect
                key={field.name}
                name={field.name}
                options={userOptions}
                onChange={field.onChange}
                values={field.value}
                shouldShowSearch={filterUsersOptions}
              />
            )}
          />
          <p className="fieldError mb-2">{errors?.user_access?.message}</p>

          <label className="my-2">Price for Program</label>
          <Controller
            key="addon_purchase_id"
            name="addon_purchase_id"
            control={control}
            render={({ field }) => (
              <SingleSelect
                key={field.name}
                name={field.name}
                options={addonPurchaseIdOptions}
                onChange={field.onChange}
               renderItem={(value: string) => (
                 <div className="flex items-center py-4 px-3">
                   <p>
                     {value}
                   </p>
                 </div>
               )} renderSelected={(selectedValues: OptionData<string>) => (
                <>
                  <p className="whitespace-nowrap text-ellipsis overflow-hidden">
                    {selectedValues.data}
                  </p>
                </>
              )} value={field.value}/>
            )}
          />
          <p className="fieldError mb-2">{errors?.addon_purchase_id?.message}</p>

          <label className="mb-2">Program Title</label>
          <div className="mb-6 relative">
            <input className="w-full" type="text" {...register('name')} />
            <div className="absolute -right-8 top-1/2 -translate-y-1/2 cursor-pointer">
              <Tooltip
                title={`Program Title is ${isHidden ? 'hidden' : 'visible'} (click to change)`}
              >
                <button type="button" className="iconButton w-10" onClick={toggle}>
                  {isHidden ? <EyeOffIcon /> : <EyeOnIcon />}
                </button>
              </Tooltip>
            </div>

            <p className="fieldError">{errors?.name?.message}</p>
          </div>
          <label className="mb-2">Description</label>
          <div className="mb-6">
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              toolbar={{ options: ['inline'] }}
            />
            <p className="fieldError">{errors?.description?.message}</p>
          </div>
          <label className="mb-2">Equipment Required</label>
          <Controller
            key="equipments"
            name="equipments"
            control={control}
            render={({ field }) => (
              <SimpleMultiSelect
                key={field.name}
                name={field.name}
                options={equipmentRequiredOptions}
                onChange={field.onChange}
                values={field.value}
              />
            )}
          />
          <p className="fieldError">{errors?.equipments?.message}</p>
          <label className="my-2">Display Tags</label>
          <Controller
            key="display_tags"
            name="display_tags"
            control={control}
            render={({ field }) => (
              <SimpleMultiSelect
                key={field.name}
                name={field.name}
                options={displayTagsOptions}
                onChange={field.onChange}
                values={field.value}
                shouldAddOption={addItemToDisplayOptions}
              />
            )}
          />
          <p className="fieldError">{errors?.display_tags?.message}</p>
          <label className="my-2">Next Program</label>
          <Controller
            key="next_program_id"
            name="next_program_id"
            control={control}
            render={({ field }) => (
              <SimpleSingleSelect
                key={field.value}
                name={field.name}
                options={nextPrograms}
                onChange={field.onChange}
                value={field.value}
              />
            )}
          />
          <p className="fieldError">{errors?.next_program_id?.message}</p>
        </div>
      </div>
      <div className="flex py-4">
        <button className="w-32 outlined narrow  text-teal-normal mr-4" onClick={goBack}>
          <ChevronLeftIcon /> <p>Back</p>
        </button>
        <div className="flex-1" />
        <button
          className="w-32 narrow  text-teal-normal mr-4"
          onClick={onClickSaveAsDraft}
          type="button"
        >
          Save as draft
        </button>
        <button className="w-32 primary narrow">Next</button>
      </div>
    </form>
  );
}

export default ProgramDetailsForm;
