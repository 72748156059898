import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import SearchField from 'common/ui/form/SearchField';
import { useModalConfig } from 'common/ui/Modal';

import AddEditStreamModal from './AddEditStreamModal';
import StreamList from './StreamList';
import { StreamFormData, StreamModel } from './types';
import useStreamList from './useStreams';

function Streams() {
  const { isModalOpen, data, openModal, setModalData, closeModal } = useModalConfig<{
    streamId: string;
    defaultFormData: StreamFormData;
  }>(false);
  const {
    filters: { searchText },
    searchStream,
  } = useStreamList();

  const onClickDataRow = (stream: StreamModel) => {
    setModalData({
      streamId: stream._id,
      defaultFormData: {
        stream_data: {
          athelete_type: stream.athelete_type._id,
          workout_group: stream.workout_group._id,
          difficulty_level: stream.difficulty_level,
          image: stream.image
        },
      },
    });
  };

  return (
    <div className="contentArea h-appContent">
      <div className="flex items-center h-pageHeader">
        <h1 className="flex-1 font-semibold text-2xl">Streams</h1>
        <SearchField placeholder="Search" defaultValue={searchText} onSearch={searchStream} />
        <button className="primary px-4 ml-4" onClick={openModal}>
          <PlusIcon /> Add Stream
        </button>
      </div>
      <div className="h-pageContent pb-4">
        <StreamList onClickDataRow={onClickDataRow} />
      </div>
      <AddEditStreamModal isOpen={isModalOpen} onClose={closeModal} {...data} />
    </div>
  );
}

export default Streams;
