import { useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as MoreHorizontalIcon } from 'assets/icons/moreHorizontal.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { athleteDifficultyLevelLabel } from 'common/constants';
import { useDeleteResource } from 'common/remoteResource/resourceCUD';
import {
  ActionColumn,
  Column,
  Content,
  DataTable,
  DataTableHeader,
  DataTableHelperInfo,
  DataTableRow,
  HeaderColumn,
  Pager,
} from 'common/ui/DataTable';
import Menu, { MenuProps } from 'common/ui/Menu';
import Throbber from 'common/ui/Throbber';

import { StreamModel } from './types';
import useStreamList from './useStreams';

const RowActions: MenuProps['items'] = [
  { name: 'editStream', display: 'Edit Stream', iconLeft: <EditIcon /> },
  {
    name: 'remove',
    display: 'Remove',
    iconLeft: <TrashIcon />,
    confirm: 'Are you sure to delete this stream?',
  },
];

function StreamList(props: { onClickDataRow: (stream: StreamModel) => void }) {
  const navigate = useNavigate();
  const { deleteResource, setDeleteInProgress, setDeleteSuccess, setDeleteError, isDeleteIP } =
    useDeleteResource({ url: '/api/admin/stream' });

  const openDetails = (stream: StreamModel) => {
    navigate(`/library/streams/${stream._id}/programs`);
  };

  const {
    isLoadingStream,
    status,
    items,
    filters: { page, perPage, sortByColumn, sortOrder },
    totalCount,
    loadStreams,
    refreshData,
    moveToPage,
  } = useStreamList({ autoLoad: true });

  const handleColumnSort = (name: string, currentSort = 'desc') => {
    loadStreams({ sortByColumn: name, sortOrder: currentSort === 'desc' ? 'asc' : 'desc' });
  };

  const handleDelete = async (streamId: string) => {
    setDeleteInProgress();
    const result = await deleteResource(streamId);
    if (result) {
      await loadStreams();
      setDeleteSuccess();
    } else {
      setDeleteError('Unable to delete stream');
    }
  };

  const handleMenuAction = (actionName: string, menuData: StreamModel) => {
    switch (actionName) {
      case 'editStream':
        props.onClickDataRow(menuData);
        break;
      case 'remove':
        handleDelete(menuData._id);
        break;
      default:
        break;
    }
  };
  return (
    <DataTable className="bg-gray-50">
      <Content>
        <DataTableHeader className="bg-gray-normal text-white text-sm font-medium ">
          <HeaderColumn>Athlete</HeaderColumn>
          <HeaderColumn className="flex-grow-[2]">Type</HeaderColumn>
          <HeaderColumn>Difficulty</HeaderColumn>
          <HeaderColumn className="justify-center">Programs</HeaderColumn>
          <HeaderColumn className="justify-center">Units</HeaderColumn>
          <HeaderColumn>Status</HeaderColumn>
          <HeaderColumn
            name="updated_at"
            sortOrder={sortByColumn === 'updated_at' ? sortOrder : undefined}
            onClick={handleColumnSort}
          >
            Last updated
          </HeaderColumn>
          <ActionColumn />
        </DataTableHeader>
        <DataTableHelperInfo status={status} items={items} refreshData={refreshData} />
        {items.map((stream) => (
          <DataTableRow
            key={stream._id}
            rowData={stream}
            className="py-2 border-b border-b-gray-light"
            onClick={openDetails}
          >
            <Column>{stream.athelete_type.name}</Column>
            <Column className="flex-grow-[2]">{stream.workout_group.name}</Column>
            <Column>{athleteDifficultyLevelLabel[stream.difficulty_level]}</Column>
            <Column className="justify-center">{stream.programs || 0}</Column>
            <Column className="justify-center">{stream.units || 0}</Column>
            <Column>{stream.is_draft ? 'Disabled' : 'Active'}</Column>
            <Column>{stream.updated_at}</Column>
            <ActionColumn>
              <Menu
                triggerElement={<MoreHorizontalIcon />}
                items={RowActions}
                menuData={stream}
                onItemClick={handleMenuAction}
              />
            </ActionColumn>
          </DataTableRow>
        ))}
      </Content>
      <Pager
        curPage={page}
        pageSize={perPage}
        totalCount={totalCount}
        isLoading={isLoadingStream}
        moveToPage={moveToPage}
      />
      {isLoadingStream || isDeleteIP ? <Throbber withMask /> : null}
    </DataTable>
  );
}

export default StreamList;
