import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import { athleteDifficultyLevelOptions } from 'common/constants';
import { useSaveResource } from 'common/remoteResource/resourceCUD';
import { SimpleSingleSelect } from 'common/ui/form/dropdown/SingleSelect';
import Modal, { ModalProps } from 'common/ui/Modal';

import ImageInputFile from "../../../common/ui/form/dndFileInput/ImageInputFile";
import {uploadFile} from "../../../common/utils/general";

import { StreamFormData, StreamFormSchema } from './types';
import useStreamOptions from './useStreamOptions';
import useStreamList from './useStreams';

const blankFormData: StreamFormData = {
  stream_data: {
    athelete_type: '',
    workout_group: '',
    difficulty_level: 'N/A',
    image: null,
  },
};
interface AddEditStreamsProps extends ModalProps {
  defaultFormData?: StreamFormData;
  streamId?: string;
}

function AddEditStreamModalComponent(props: AddEditStreamsProps) {
  const { streamId, defaultFormData = blankFormData, onClose, ...restProps } = props;
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<StreamFormData>({
    defaultValues: defaultFormData,
    resolver: zodResolver(StreamFormSchema),
  });
  const { filters, refreshData, loadStreams } = useStreamList();
  const { isOptionsLoading, athleteTypeOptions, workoutGroupOptions } = useStreamOptions({
    autoLoad: true,
  });

  const {
    isSaveIP,
    isSaveError,
    saveMessage,
    setSaveInProgress,
    setSaveError,
    saveResource,
    resetSuccessCount,
    getSuccessCount,
  } = useSaveResource({ url: '/api/admin/stream' });

  const handleSaveStream = async (payload: StreamFormData) => {
    setSaveInProgress();
    const detailsPayload = { ...getValues() };
    payload.stream_data.image = await uploadFile(detailsPayload.stream_data.image);
    const result = await saveResource({ payload, resourceId: streamId });
    if (result) {
      await loadStreams({page: 1});
      onClose();
    } else {
      setSaveError('Unable to save stream.');
    }
  };

  const handleClose = () => {
    const successCount = getSuccessCount();
    if (streamId && successCount > 0 && filters.sortByColumn !== 'updated_at') {
      refreshData();
    } else if (successCount > 0) {
      loadStreams({ page: 1 });
    }
    resetSuccessCount();
    onClose();
  };
  return (
    <Modal
      className="w-full md:w-modal-sm max-w-screen-lg"
      onClose={handleClose}
      isLoading={isOptionsLoading || isSaveIP}
      {...restProps}
    >
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleSaveStream)}>
        <div className="max-h-pageContent overflow-y-auto px-9 pt-6 bg-white text-md">
          <h1 className="text-xl">{streamId ? 'Update Stream' : 'Create New Stream'}</h1>
          <p
            className={clsx('flex-1 my-2', isSaveError ? 'text-alert-error' : 'text-alert-success')}
          >
            {saveMessage}
          </p>
          <div className="flex-1 mb-8">
            <label className="mb-2">Cover Image</label>
            <Controller
                control={control}
                name="stream_data.image"
                render={({ field }) => (
                    <ImageInputFile
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        className="h-60 w-60 text-sm"
                    />
                )}
            />
            <p className="fieldError">{errors?.stream_data?.image?.message}</p>
          </div>
          <div className="flex-1 mb-8">
            <label className="mb-2">Athlete</label>
            <Controller
              name="stream_data.athelete_type"
              control={control}
              render={({ field }) => (
                <SimpleSingleSelect
                  name={field.name}
                  options={athleteTypeOptions}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
            <p className="fieldError">{errors?.stream_data?.athelete_type?.message}</p>
          </div>
          <div className="flex-1 mb-8">
            <label className="mb-2">Type</label>
            <Controller
              name="stream_data.workout_group"
              control={control}
              render={({ field }) => (
                <SimpleSingleSelect
                  name={field.name}
                  options={workoutGroupOptions}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
            <p className="fieldError">{errors?.stream_data?.workout_group?.message}</p>
          </div>
          <div className="flex-1 mb-8">
            <label className="mb-2">Difficulty</label>
            <Controller
              name="stream_data.difficulty_level"
              control={control}
              render={({ field }) => (
                <SimpleSingleSelect
                  name={field.name}
                  placeHolder="Select difficulty"
                  options={athleteDifficultyLevelOptions}
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />
            <p className="fieldError">{errors?.stream_data?.difficulty_level?.message}</p>
          </div>
        </div>
        <div className="flex px-8 py-4">
          <button className="flex-1 primary narrow">{streamId ? 'Update' : 'Save'}</button>
        </div>
      </form>
    </Modal>
  );
}

const AddEditStreamModal = (props: AddEditStreamsProps) => {
  if (props.isOpen) {
    return <AddEditStreamModalComponent {...props} />;
  }
  return null;
};
export default AddEditStreamModal;
